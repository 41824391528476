import * as React from "react";
import SiteContainer from "../../../components/site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import server_cluster from '../../../images/vectors/undraw_server_cluster_jwwq.svg';
import rules from '../../../images/vectors/undraw_functions_egi3.svg';
import PricingSection from "../../../components/PricingSection";
import CoreConcept from "../../../components/CoreConcept";

const IndexPage = () => {
    return (
        <SiteContainer>
            <Helmet>
                <title>Firstparty - Event-driven Realtime Marketing Automation</title>
                <meta name="description" content="React to customer interactions in realtime and update
                                customer information, push data to an external service, or even run custom Javascript
                                on any Event you collect." />
                <link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎉</text></svg>" />
            </Helmet>

            <nav className="bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb breadcrumb-scroll">
                                <li className="breadcrumb-item">
                                    <span className="text-gray-700">
                                        Solutions
                                    </span>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Automation
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>
            </nav>

            <section className="py-8 py-md-11 border-bottom">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} className="text-center">
                            <h1 className="display-1 fw-bold">Event-driven Realtime Marketing Automation
                            </h1>
                            <p className="lead text-muted mb-6">
                                React to customer interactions in realtime with a simple rules engine that lets you update
                                customer information, push data to an external service, or even run custom Javascript
                                on any Event you send to Firstparty.
                            </p>
                            <p className="mb-7 mb-md-9 aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                <a className="btn btn-primary shadow lift" href="https://app.firstpartyhq.com/authentication/signup">
                                    Get Started with 1 Million Free Events<i className="fe fe-arrow-right ms-2" />
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div id="automation-steps-container" className="section bg-dark pt-8 pb-8 pb-md-0">
                <div className="container">
                    <div className="ml-auto mr-auto">
                        <img className="img-fluid w-100 mb-6 mb-md-0" src={rules} />
                    </div>
                    <div className="automation-headline row justify-content-between align-items-center mb-8">
                        <div className="col-12 col-md-8">
                            <h2 className="text-white">
                                Build powerful automations with Rules
                            </h2>
                            <div className="lead text-gray-200 mb-0">
                                Rules allow you to control the Actions that should be taken based on a set of Conditions evaluated against every Event you collect with Firstparty.
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">
                                            <a className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1"
                                               href="#!">
                                                <span>1</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <hr className="d-none d-md-block bg-secondary me-n7" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">
                                    <h3 className="text-white">
                                        Create Unlimited Rules
                                    </h3>
                                    <p className="text-gray-200 mb-6 mb-md-0">
                                        Define automations that execute in response to collected Events.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">
                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>2</span>
                                            </a>
                                        </div>
                                        <div className="col">
                                            <hr className="d-none d-md-block bg-secondary me-n7" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">
                                    <h3 className="text-white">
                                        Set Conditions & Actions
                                    </h3>
                                    <p className="text-gray-200 mb-6 mb-md-0">
                                        Control the Actions that should be taken based on a set of
                                        Conditions evaluated against every Event you collect with Firstparty.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="row">
                                <div className="col-auto col-md-12">
                                    <div className="row gx-0 align-items-center mb-md-5">
                                        <div className="col-auto">
                                            <a href="#!"
                                               className="btn btn-sm btn-rounded-circle btn-gray-400-10 disabled opacity-1">
                                                <span>3</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-md-12 ms-n5 ms-md-0">
                                    <h3 className="text-white">
                                        Evaluate & Execute Automatically
                                    </h3>
                                    <p className="text-gray-200 mb-0">
                                        Rules execute all of their Actions when all of their specified Conditions are
                                        true. Conditions may reference Events, Sessions, Profiles, Sources, and
                                        Segments, while Actions may utilize Connections when interacting with external
                                        systems.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="position-relative">
                <div className="shape shape-top shape-fluid-x shape-flip-x text-dark">
                    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            {/*<section className="bg-light pt-14 pt-10 pb-10">*/}
            {/*    <div className="container">*/}
            {/*        <div className="row mb-5 text-center">*/}
            {/*            <div className="col-12 col-md-8 offset-md-2 mb-5">*/}
            {/*                <h2 className="mb-0">Rule Conditions Trigger Actions</h2>*/}
            {/*                <h3>Rules are evaluated against each collected Event, and trigger Actions in response to successful Conditions.</h3>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row mb-5">*/}
            {/*            <div className="col-12 col-md-5">*/}
            {/*                <div className="card bg-gray-900">*/}
            {/*                    <div className="card-body d-flex flex-column h-100 justify-between">*/}
            {/*                            <p className="text-white">Conditions Evaluate</p>*/}
            {/*                            <p className="text-gray-400">*/}
            {/*                                Conditions make it easy to only perform certain Actions when certain things are true.*/}
            {/*                                Every time an Event is received, Rules are each evaluated based on their configured Conditions.*/}
            {/*                                If the Condition evaluates as true, the Rule's Actions are executed.*/}
            {/*                            </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-md-2 text-center d-flex justify-content-center align-items-center text-dark">*/}
            {/*                <DoubleArrowIcon style={{fontSize: 100}} />*/}
            {/*            </div>*/}
            {/*            <div className="col-12 col-md-5">*/}
            {/*                <div className="card bg-gray-900 h-100">*/}
            {/*                    <div className="card-body d-flex flex-column h-100 justify-between">*/}
            {/*                        <div>*/}
            {/*                            <p className="text-white">Actions Execute</p>*/}
            {/*                            <p className="text-gray-400">*/}
            {/*                                Actions are the second half of a Rule execution. If a triggering Event's Rule(s) Condition evaluates as true, the Rule's Actions are executed.*/}
            {/*                            </p>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        /!*<div className="row">*!/*/}
            {/*        /!*    <div className="col">*!/*/}
            {/*        /!*        <Accordion className="accordion mt-5">*!/*/}
            {/*        /!*            <Accordion.Item eventKey="0" className="accordion-item">*!/*/}
            {/*        /!*                <Accordion.Button as="div">*!/*/}
            {/*        /!*                    <div className="me-auto">*!/*/}
            {/*        /!*                        <p className="fw-bold mb-0" id="featuresOneHeading">*!/*/}
            {/*        /!*                            Match Event Property*!/*/}
            {/*        /!*                        </p>*!/*/}
            {/*        /!*                        <p className="fs-sm text-muted mb-0">*!/*/}
            {/*        /!*                            Require a matching Event property value*!/*/}
            {/*        /!*                        </p>*!/*/}
            {/*        /!*                    </div>*!/*/}
            {/*        /!*                </Accordion.Button>*!/*/}
            {/*        /!*            </Accordion.Item>*!/*/}
            {/*        /!*            <Accordion.Item eventKey="1">*!/*/}
            {/*        /!*                <Accordion.Button as="div">*!/*/}
            {/*        /!*                    <div className="me-auto">*!/*/}
            {/*        /!*                        <p className="fw-bold mb-0" id="featuresOneHeading">*!/*/}
            {/*        /!*                            Match Event Name*!/*/}
            {/*        /!*                        </p>*!/*/}
            {/*        /!*                        <p className="fs-sm text-muted mb-0">*!/*/}
            {/*        /!*                            Require a specific Event*!/*/}
            {/*        /!*                        </p>*!/*/}
            {/*        /!*                    </div>*!/*/}
            {/*        /!*                </Accordion.Button>*!/*/}
            {/*        /!*            </Accordion.Item>*!/*/}
            {/*        /!*        </Accordion>*!/*/}

            {/*        /!*    </div>*!/*/}
            {/*        /!*</div>*!/*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section className="pt-10 pt-md-15 pb-10 pb-md-14 bg-light">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-10 text-center">
                            <h6 className="text-uppercase text-primary">
                                Attribution
                            </h6>
                            <h2>Rules Make Attribution Even More Powerful</h2>
                            <p className="fs-lg text-gray-700 pb-5">
                                Attribute your customers' activities to marketing efforts in external systems with
                                Firstparty Rules. Send offline and end-of-funnel conversions to ad networks and other
                                top-of-funnel channel platforms in just a few clicks.
                            </p>
                            <a href="/solutions/attribution/" className="btn btn-primary-soft">Explore Attribution</a>
                        </div>
                    </div>
                </div>
            </section>

            <CoreConcept
                title="Rules define automations that execute in response to collected Events."
                documentation_link="/docs/rules/overview/"
                api_link="/docs/api/reference/#tag/Rules"
            >
                <p className="text-gray-400">
                    Rules allow you to control the Actions that should be taken based on a set of Conditions evaluated against every Event you collect with Firstparty.
                </p>
            </CoreConcept>

            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-between align-items-top">
                        <Col md={6} lg={5} className="order-md-2">
                            <div className="d-flex flex-column w-md-130 mb-6 mb-md-0">
                                <img src={server_cluster} />
                            </div>
                        </Col>
                        <Col md={6} className="order-md-1">
                            <h6 className="text-uppercase text-primary">
                                Activate first-party data
                            </h6>
                            <h2>
                                Trigger automations <br />from your data warehouse
                            </h2>
                            <p className="fs-lg text-gray-700 mb-6">
                                It's time to truly activate your warehouse. Firstparty generates Events for every row
                                returned from a warehouse query, so you can take action on data previously locked away
                                in storage.
                            </p>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            &nbsp;1&nbsp;
                                        </div>
                                        <p>
                                            Query your existing Postgres or Redshift data warehouse
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            &nbsp;2&nbsp;
                                        </div>
                                        <p>
                                            Generate an Event for each row returned from the query
                                        </p>
                                    </div>
                                    <div className="d-flex">
                                        <div className="badge badge-rounded-circle bg-primary-soft mt-1 me-4">
                                            &nbsp;3&nbsp;
                                        </div>
                                        <p>
                                            Access and activate the fields from each returned row of data in your Rule
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="py-8 py-md-11">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-6 img-skewed img-skewed-end">
                            <div className="card rounded-lg bg-black mb-6 mb-md-0 img-skewed-item screenshot">
                                <div className="card-header border-white-10">
                                    <div className="d-flex">
                                        <span className="bg-danger rounded-circle"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-warning rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                        <span className="bg-success rounded-circle ms-1"
                                              style={{width: "10px", height: "10px"}} />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <code className="highlight hljs"
                                          style={{minHeight: "183px"}}>
                                        exports.handler = (event, context, callback) => &#123;
                                        <br /><br />
                                        <div className="px-3">
                                            <span className="text-success">// Set a key/value on the Event</span>
                                            <br />
                                            event.setProperty('barfoo', 'foobar');
                                            <br /><br />
                                            <span className="text-success">// Make an HTTP request with node-fetch</span>
                                            <br />
                                            fetch('https://api.github.com/users/github')
                                            <br />
                                            <div className="px-3">
                                                .then(res => res.json())
                                                <br />
                                                .then(json => &#123;
                                                <br /><br />
                                                <div className="px-3">
                                                    <span className="text-success">// Set a key/value on the Event</span>
                                                    <br />
                                                    event.setProperty('payload', json);
                                                    <br /><br />
                                                    <span className="text-success">// Finish work and end this Action's execution</span>
                                                    <br />
                                                    callback();
                                                    <br />
                                                </div>
                                                &#125;);
                                            </div>
                                        </div>
                                        &#125;
                                    </code>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-5 offset-md-1">
                            <h6 className="text-uppercase text-primary">
                                Activate first-party data
                            </h6>
                            <h2>Execute custom Javascript in response to any Event</h2>
                            <p className="fs-lg text-gray-700">
                                Write Javascript that executes in response to any Event in a secure, sandboxed
                                Node environment. Javascript Actions have access to the triggering Event, Session, and
                                Profile, and can make HTTP requests to interact with external systems.
                            </p>
                            <a className="fw-bold text-decoration-none" href="#!">
                                See our easy installation instructions <i className="fe fe-arrow-right ms-1"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <PricingSection />

        </SiteContainer>
    )
}

export default IndexPage
